// UI for front
import naja from 'naja';
import netteForms from 'nette-forms';
naja.formsHandler.netteForms = netteForms;

$(function(){
    "use strict";
    naja.initialize(
        {
            history: false,
            historyUiCache: false
        }
    );

    /*=========================================================================
            Vertical Menu
    =========================================================================*/
    $( ".submenu" ).before( '<i class="icon-arrow-down switch"></i>' );

    $(".vertical-menu li i.switch").on( 'click', function() {
        var $submenu = $(this).next(".submenu");
        $submenu.slideToggle(300);
        $submenu.parent().toggleClass("openmenu");
    });

    /*=========================================================================
            Canvas Menu
    =========================================================================*/
    $("button.burger-menu").on( 'click', function() {
        $(".canvas-menu").toggleClass("open");
        $(".main-overlay").toggleClass("active");
    });

    $(".canvas-menu .btn-close, .main-overlay").on( 'click', function() {
        $(".canvas-menu").removeClass("open");
        $(".main-overlay").removeClass("active");
    });

    /*=========================================================================
            Popups
    =========================================================================*/
    $("button.search").on( 'click', function() {
        $(".search-popup").addClass("visible");
    });

    $(".search-popup .btn-close").on( 'click', function() {
        $(".search-popup").removeClass("visible");
    });

    $(document).keyup(function(e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            $(".search-popup").removeClass("visible");
        }
    });

    /*=========================================================================
            Tabs loader
    =========================================================================*/
    $('button[data-bs-toggle="tab"]').on( 'click', function() {
        $(".tab-pane").addClass("loading");
        $('.lds-dual-ring').addClass("loading");
        setTimeout(function () {
            $(".tab-pane").removeClass("loading");
            $('.lds-dual-ring').removeClass("loading");
        }, 500);
    });

    /*=========================================================================
            Social share toggle
    =========================================================================*/
    $('.post button.toggle-button').each( function() {
        $(this).on( 'click', function(e) {
            $(this).next('.social-share .icons').toggleClass("visible");
            $(this).toggleClass('icon-close').toggleClass('icon-share');
        });
    });

    /*=========================================================================
    Spacer with Data Attribute
    =========================================================================*/
    var list = document.getElementsByClassName('spacer');

    for (var i = 0; i < list.length; i++) {
        var size = list[i].getAttribute('data-height');
        list[i].style.height = "" + size + "px";
    }

    /*=========================================================================
    Background Image with Data Attribute
    =========================================================================*/
    var list = document.getElementsByClassName('data-bg-image');

    for (var i = 0; i < list.length; i++) {
        var bgimage = list[i].getAttribute('data-bg-image');
        list[i].style.backgroundImage  = "url('" + bgimage + "')";
    }

    $('*[data-form="search"]').each(function(){
        const searchForm = $(this);
        searchForm.submit(function (e) {
            e.preventDefault();
        });

        const title = searchForm.find('*[data-title]');
        title.autocomplete({
            minLength: 2,
            source: function( request, response ) {
                naja.makeRequest('GET', searchForm.data('url'), {
                    [title.data('title')]: title.val()
                }, { history: false }).then(payload => {
                    response( $.map( payload, function( row ) {
                        return row;
                    }));
                })
            }
        }).data( 'ui-autocomplete' )._renderItem = function( ul, item ) {
            return $( '<li></li>' )
                .data( 'item.autocomplete', item )
                .append( '<a href="' + item.link + '">' + item.label + '</a>' )
                .appendTo( ul );
        };
    });
});
